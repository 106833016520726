import {
  Card,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Page from '#/components/shared/ui/Page';
import { PATH_AFTER_LOGIN } from '#/config';
import useLocales from '#/hooks/useLocales';

const ThankyouCard = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { state } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      navigate(
        state?.redirectLink || PATH_AFTER_LOGIN,
        state?.redirectLink ? { replace: true } : undefined
      );
    }, 2000);
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <Page title="Welcome | gut geregelt">
      <Container component="main">
        <Typography
          variant="h2"
          sx={{
            marginBottom: 12,
            textAlign: 'center',
          }}
        >
          {String(translate('pensionProvision.thankyouCard.title'))}
        </Typography>
        <Card sx={{ p: 3 }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mb: 3 }}
            spacing={2}
          >
            <Typography variant="body1">
              {String(translate('pensionProvision.thankyouCard.thankYou'))}
            </Typography>
            <CircularProgress size={24} />
          </Stack>
          <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
            {String(translate('pensionProvision.thankyouCard.gentlyReminder'))}
          </Typography>
        </Card>
      </Container>
    </Page>
  );
};

export default ThankyouCard;
